import { Local } from '@/common/cookie';
import router from "@/router";
import store from "@/store";
import axios from "axios";
import { Message, MessageBox } from 'element-ui';


//封装axios实例
const http = axios.create({
  // baseURL: process.env.VUE_APP_BASE_UPL,
  // baseURL: 'https://new.arts6.cn/web',
  baseURL: `${process.env.VUE_APP_BASE_URL}/web`
})

//请求拦截
http.interceptors.request.use(config => {
  if (Local.getToken('token')) {
    const token = Local.getToken('token')
    config.headers.Authorization = token
  }
  store.commit('setLoading', true)
  return config
}, err => {
  return Promise.reject(err)
})


//响应拦截器
http.interceptors.response.use(res => {
  if (res.data.code === 401 || res.data.code === 4001) {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      // 当前设备是移动设备
      alert('您还未登录，请先去登陆')
      Local.clear()
      store.commit('setToken', '')
      router.push('/login')
    } else {
      //pc端设备
      MessageBox.confirm('您还未登录，请先去登陆', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        Local.clear()
        store.commit('setToken', '')
        router.push('/login')
      }).catch(() => {
        if (router.history.current.query.show) {
          router.push('/home')
        } else {
          router.back()
        }
      });
    }
    store.commit('setLoading', false)
    return res.data
  } else if (res.data.code === 503) {
    router.push({ path: '/503', query: { data:res.data.data } })
  }

  store.commit('setLoading', false)
  return res.data
}, error => {
  // 对响应错误做点什么
  if (error.message.indexOf('timeout') !== -1) {
    Message.error('网络超时');
  } else if (error.message == 'Network Error') {
    Message.error('网络连接错误');
  } else {
    if (error.response.data) Message.error(error.response.statusText);
    else Message.error('接口路径找不到');
  }
  store.commit('setLoading', false)
  return Promise.reject(error);
})

export default http