<template>
  <div>
    <!-- PC端 -->
    <div class="header-container">
      <div class="header-box">
        <router-link to="/home">
          <div class="left-img-box">
            <img class="logo" :src="$host + `${iconUrl ? iconUrl : url}`" />
            <span>{{ title }}</span>
          </div>
        </router-link>
        <div class="lang zh_click" >
          <div @click="setLang('s')" class="zh_click" id="zh_click_s">简体</div>
          |
          <div @click="setLang('t')" class="zh_click" id="zh_click_t">繁體</div>
        </div>
        <div class="right-box right-status">
          <div class="right-text-box">
            <div class="text-top-box">
              <ul>
                <li class="dropdown">
                  <!-- <router-link>登录</router-link> -->
                  <router-link style v-if="!token" to="/enroll"
                    >你好！请注册 {{ value }}</router-link
                  >
                  <div v-else>
                    <div>{{ infoName }} 你好</div>
                    <div class="dropdown-box">
                      <div class="dropdown-item">
                        <router-link to="/info" class="dropdown-item"
                          >个人中心</router-link
                        >
                      </div>
                      <div class="dropdown-item">
                        <router-link to="/pwd" class="dropdown-item"
                          >修改密码</router-link
                        >
                      </div>
                      <div class="dropdown-item">
                        <router-link
                          to="/onlineSignUpList"
                          class="dropdown-item"
                          >报名列表</router-link
                        >
                      </div>
                      <div class="dropdown-item" @click="loginOut">
                        退出登入
                      </div>
                    </div>
                  </div>
                </li>
                <!-- <li>
                  <router-link to="/examrelease">
                    成绩发布
                  </router-link>
                </li>
                <li>
                  打印通知
                </li>
                <li>
                 
                  <router-link to="/examtime">
                    考试时间
                  </router-link>
                </li>-->
              </ul>
            </div>
            <div class="text-bottom-box">
              <ul>
                <li
                  @click="activeIcon"
                  v-for="(item, index) in infoList"
                  :key="index"
                >
                  <router-link :to="item.href">{{ item.title }}</router-link>
                </li>
                <!-- <li>
                  <router-link to="/award"> 奖状 </router-link>
                </li>-->
                <!-- <li>
                  打印通知
                </li>-->
              </ul>
            </div>
          </div>
          <div class="right-sign-up-box">
            <router-link style="color: #fff" :to="{ path: '/onlineSignUp' }">
              <span>线上报名</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- 手机端 -->
    <div class="phone-container">
      <div class="phone-box">
        <div class="left-box">
          <router-link to="/home">
            <div class="left-img-box">
              <el-image
                class="logo"
                :src="$host + `${iconUrl ? iconUrl : url}`"
              ></el-image>
              <span>{{ title }}</span>
            </div>
          </router-link>
          <div class="lang">
            <div @click="setLang('s')"   class="zh_click" id="zh_click_s">简</div>
            |
            <div @click="setLang('t')"    class="zh_click" id="zh_click_t">繁</div>
          </div>
        </div>

        <div class="right-box">
          <!-- <div class="right-title-box" >
            <router-link to="/login">登入 / 注册</router-link>
          </div>-->
          <!-- <div v-else class="phone-after-title">
            <div class="phone-user">{{ infoName }} 你好</div>
            <div class="dropdown-box">
              <div class="dropdown-item"><router-link to="/info" class="dropdown-item"> 个人中心 </router-link></div>
              <div class="dropdown-item">
                <router-link to="/pwd" class="dropdown-item"> 修改密码 </router-link>
              </div>
              <div class="dropdown-item" @click="loginOut">退出登入</div>
            </div>
          </div>-->
          <div class="bm">
            <router-link style="color: #fff" :to="{ path: '/onlineSignUp' }">
              <span>线上报名</span>
            </router-link>
           </div>
          <!-- <div class="phone-after-title" v-if="value">
           <i class="el-icon-s-operation" style="font-size: 28px; color: #601986"></i> -->
          
            <!-- <div class="phone-user">{{ infoName }} 你好</div> -->
          <!-- </div> -->
          <div class="icon-box">
            <div v-if="isShow" @click="initIcon">
              <i
                class="el-icon-s-operation"
                style="font-size: 28px; color: #601986"
              ></i>
            </div>
            <div v-else class="error-icon" @click="activeIcon">
              <i
                class="el-icon-close"
                style="font-size: 28px; color: #601986"
              ></i>
            </div>
          </div>
        </div>
        <div class="list-box" id="initIcon">
          <div class="phone-user">{{ infoName }} ，你好</div>
          <ul>
            <li
              @click="activeIcon"
              v-for="(item, index) in infoList"
              :key="index"
            >
              <router-link :to="item.href">{{ item.title }}</router-link>
              <!-- <router-link to="/onlineSignUpList">报名列表</router-link> -->
            </li>
            <li @click="activeIcon" v-show="value">
              <router-link to="/info">个人中心</router-link>
            </li>
            <li @click="activeIcon" v-show="value">
              <router-link to="/onlineSignUpList">报名列表</router-link>
            </li>
            <li @click="activeIcon" v-show="value">
              <router-link to="/pwd">修改密码</router-link>
            </li>
            <li @click="activeIcon" v-show="value">
              <a @click="phoneLoginOut">退出登录</a>
            </li>
            <li @click="activeIcon" v-show="!value">
              <router-link to="/enroll">立即注册</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <PopUP v-model="isModel"></PopUP>
  </div>
</template>

<script>
import { getMenu } from "@/api/news/index";
import { Lang } from "@/common/chinese";
import { Local } from "@/common/cookie";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    iconUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      f:true,
      sysCopyrightData: [],
      isCollapse: true,
      showmenu: false,
      activeIndex: "/",
      token: Local.getToken("token"),
      isShow: true,
      isModel: false,
      infoList: [],
    };
  },
  watch: {
    $route() {
      this.setCurrentRoute(); //监测当前路由
    },
  },
  computed: {
    value() {
      return (this.token = this.$store.state.token
        ? this.$store.state.token
        : Local.getToken("token"));
    },
    infoName() {
      return this.$store.state.username
        ? this.$store.state.username
        : Local.getToken("userInfo")?.name;
    },
    url() {
      return Local.getToken("url");
    },
  },
  methods: {
    setLang(v) {
      

      Lang.zh_tran(v);
      Lang.zh_getLang()=='s'?this.f=true:this.f=false;
    },
    async getInfo() {
      const res = await getMenu();
      if (res.code == 0) {
        this.infoList = res.data;
        //
        // console.log(this.infoList);
      }
    },
    setCurrentRoute() {
      this.showmenu = false;
    },
    ismobilemenu() {
      this.showmenu = !this.showmenu;
    },
    loginOut() {
      this.$confirm("此操作将退出登入, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "persdsd",
      })
        .then(() => {
          Local.clear();
          this.$store.commit("setToken", "");
          this.$router.push("/login");
          this.$MessageTip.success({
          info:"退出登入成功!"
        });
          
        })
        .catch(() => {});
    },
    phoneLoginOut() {
      this.isModel = true;
    },
    initIcon() {
      const menu = document.getElementById("initIcon");
      menu.style.height = "100%";
      this.isShow = false;
    },
    activeIcon() {
      const menu = document.getElementById("initIcon");
      menu.style.height = "0";
      this.isShow = true;
    },
  },
  created() {
    this.getInfo();
    Lang.zh_getLang()=='s'?this.f=true:this.f=false;
  },
};
</script>

<style lang="less" scoped src="@/css/header/PCHeader.less"></style>
<style lang="less" scoped>
.phone-user{
  width: 100%;
  display: flex;
  justify-content: center;
  color: #707070;
}
.active{
  text-shadow: 0px 3px 3px #7e657c;
  color: #811781;
}
.bm{
  background-color: #601986;
  height: 100%;
  display: flex;
  align-items: center;
  padding:0 10px;
  margin-right: 10px;

  a{
    text-decoration: none;
  }
}
.left-box{
  display: flex;
  justify-content: center;
  align-items: center;
font-size: 11px;

}
.left-box a{
  height: 100%;
}
.lang {
  display: flex;
  letter-spacing: 0.2em;
  margin-left: 10px;
  font-weight: bold;
}

ul {
  list-style: none;
}

.phone-container {
  display: none;
}

@media (max-width: 1200px) {
  .header-container {
    display: none;
  }

  .phone-container {
    display: block;
    height: 8%;
  }

  .phone-container .phone-box {
    width: 100%;
    height: 8%;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .list-box {
      position: fixed;
      top: 8%;
      left: 0;
      background-color: #fff;
      z-index: 999;
      width: 100%;
      height: 0;
      overflow: auto;
      transition: all 1s ease;

      ul {
        margin: 0;
        padding: 0 0 50px 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 30px 20px 20px;
          padding: 30px;
          border-bottom: 1px solid #666;

          a {
            font-size: 15px;
            color: #601986;
            font-weight: bold;
            margin-right: 5px;
          }

          .list-bottom-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
  }

  .phone-container .phone-box .left-img-box {
    display: flex;
    align-items: center;
    width: fit-content;
    height: 100%;
    // margin-left: 10px;
    cursor: pointer;

    .logo {
      width: 100%;
      max-width: 100px;
      height: 100%;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      min-width: 120px;
      font-size: 12px;
      height: 100%;
      font-weight: bold;
      color: #601986;
    }
  }

  .phone-box .right-box {
    display: flex;
    align-items: center;
    margin-right: 10px;

    .right-title-box {
      padding: 5px;
      height: 30px;
      background-color: #601986;
      border-radius: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;

      a {
        font-size: 12px;
        color: #fff;
      }
    }

    .phone-after-title {
      color: #601986;
      font-size: 12px;
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
  }
}
</style>
