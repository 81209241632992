var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isShow)?_c('div',{class:[_vm.type, 'info', 'box'],style:('top:' + _vm.top + 'px')},[_c('i',{class:_vm.type == 'success'
      ? 'el-icon-success'
      : _vm.type == 'error'
        ? 'el-icon-error'
        : _vm.type == 'warning'
          ? 'el-icon-warning'
          : 'message'}),_vm._v(" "+_vm._s(_vm.info)+" ")]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }