import http from "@/utils/request";


export const submitPayApi = (data) => {
  return http({
    url:'pay/index',
    method:'POST',
    data
  })
}
// export const updateUserPhoneCapt = (data) => {
//   return http({
//     url:'update/UserPhoneCapt',
//     method:'POST',
//     data
//   })
// }
//获取用户信息
export const getUserInfoApi = () => {
  return http({
    url:'member/info',
    method:'GET'
  })
}
//获取用户角色列表
export const getCharacterList = () => {
  return http({
    url:'member/EnrollmentModel',
    method:'GET'
  })
}
//获取报名生份
export const getidenes = (data) => {
  return http({
    url: 'bookingform/getIdentConfig',
    method: 'POST',
    data
  })
}
// 用户修改信息
export const updateUserInfoApi = (data) => {
  return http({
    url:'member/updateInfo',
    method:'POST',
    data
  })
}
//用户修改密码
export const updatePwdApi = (data) => {
  return http({
    url:'member/password',
    method:'POST',
    data
  })
}
//获取找回密码邮箱验证码
export const getEmailCodeApi = (data) => {
   return http({
    url:'member/retrievePwdSendMail',
    method:'POST',
    data
   })
}
//邮箱登录验证
export const emailLoginCaptchaApi = (data) => {
  return http({
    url:'member/loginMailCapt',
    method:'POST',
    data
  })
}
//找回密码的短信验证-手机
export const retrievePwdSendSmsApi = (data) => {
  return http({
    url:'member/retrievePwdSendSms',
    method:'POST',
    data
  })
}
//网站配置
export const sysCopyrightApi = () => {
   return http({
     url:'sysCopyright/index',
     method:'GET'
   })
}
//支付列表
export const getPayListApi = () => {
  return http({
    url:'/pay/list',
    method:'GET'
  })
}
//参照机构列表
export const getAgenciesApi = () => {
   return http({
    url:'agencies/list',
    method:'GET'
   })
}
//关于我们
export const getAboutApi = () => {
   return http({
    url:'/about',
    method:'GET'
   })
}
//评委列表
export const getExpertListApi = (page,pageSize) => {
  return http({
     url:`expert/list?page=${page}&pageSize=${pageSize}`,
     method:'GET',
  })
}
//评委详情
export const getExpertDetailApi = (id) => {
  return http({
     url:`expert/${id}`,
     method:'GET',
  })
}
//承办商认证
export const getAxamareaApplicationApi = (data) => {
   return http({
     url:'online/examareaApplication',
     method:'POST',
     data
   })
}
//
export const updateUserPhoneCapt = (data) => {
  return http({
    url:'member/updateUserPhoneCapt',
    method:'POST',
    data
  })
}
export const validateMobele = (data) => {
   return http({
     url:'member/validateMobele',
     method:'POST',
     data
   })
}
export const updateUserMail = (data) => {
  return http({
    url:'member/updateUserMail',
    method:'POST',
    data
  })
}
export const validateMailCapt = (data) => {
  return http({
    url:'member/validateMailCapt',
    method:'POST',
    data
  })
}
export const getPlayConfig = (data) => {
  return http({
    url:'pay/config',
    method:'POST',
    data
  })
}