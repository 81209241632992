import { getUserInfoApi } from '@/api/user/index'
import { Local } from '@/common/cookie'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    registrationTime:{},
    loading:false,
    content:'正在快马加鞭的加载中....',
    queryPath:null,
    path:'',
    opstions:[],
    addressList:[],
    personal: {},  //个人信息
    username: '',
    token: '',   //登录用户令牌
    expirestime: 86400000,  //令牌过期时间
    syllabus: [],  //考试大纲列表
    isSyllabus: false,
    aboutData: [], //关于页面的数据
    isAbout: false,
    examareas: [], //考点列表
    isExamareas: false,
    committees: [],  //专家评审
    isCommittees: false,
    partners_0: [],  //合作机构
    partners_1: [],  //认可机构
    isPartners_0: false,
    isPartners_1: false,
  },
  getters: {
  },
  mutations: {
    //设置内容
    setContent(state,payload) {
       state.content = payload
    },
    //请求数据
    setLoading(state, payload) {
      state.loading = payload
    },
    // 请求token
    setToken(state, payload) {
      state.token = Local.getToken('token')
    },
    // 登录用户信息
    setPersonal(state, payload) {
      state.personal = payload
    },
    // 关于页面数据
    setAbout(state, aboutData) {
      state.aboutData = aboutData
      state.isAbout = true
    },
    // 考试大纲
    setSyllabus(state, syllabus) {
      state.syllabus = syllabus
      state.isSyllabus = true
    },
    // 考点列表
    setSite(state, examareas) {
      state.examareas = examareas
      state.isExamareas = true
    },
    // 专家评审
    setCommittees(state, committees) {
      state.committees = committees
      state.isCommittees = true
    },
    // 合作机构
    setPartners_0(state, partners_0) {
      state.partners_0 = partners_0
      state.isPartners_0 = true
    },
    //  认可机构
    setPartners_1(state, partners_1) {
      state.partners_1 = partners_1
      state.isPartners_1 = true
    },
  },
  actions: {
    //获取用户信息
    async userInfo({ state, commit }) {
      const res = await getUserInfoApi()
      if (res.code == 0) {
        Local.setToken('userInfo',res.data.memberInfo)
        state.username = res.data.memberInfo.name
        commit('setPersonal', res.data.memberInfo)
      }
    }
  },
  modules: {
    
  }
})
