import http from "@/utils/request";

//新闻列表
export const getNewsListApi = (id,page,pageSize) => {
  return http({
    url:`examnews/list/${id}?page=${page}&pageSize=${pageSize}`,
    method:'GET'
  })
}
//获取合作与认可机构列表
export const getPartnersListApi = (params) => {
  return http({
    url:`partners/list`,
    method:'GET',
    params:params
  })
}
//合作伙伴详情
export const getPartnersDetailApi = (id) => {
  return http({
    url:`partners/info?id=${id}`,
    method:'GET'
  })
}
//轮播图
export const getBanner = (id) => {
  return http({
    url:`/index/banner`,
    method:'GET'
  })
}
//菜单
export const getMenu = (id) => {
  return http({
    url:`/index/frontMenu`,
    method:'GET'
  })
}