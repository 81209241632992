import Vue from 'vue';
import Message from './index.vue';

let MessageConstructor = Vue.extend(Message)
let instance
var count=0;
var sumHeight=0
const MessageTip ={
    success(options = {}) {
        instance = new MessageConstructor({
            data: {
                type:'success',
               ...options
            }
        })
       this.remove()
    },
    message(options = {}) {
        instance = new MessageConstructor({
            data: {
                type:'message',
               ...options
            }
        })
        this.remove()
    },
    warning(options = {}) {
        instance = new MessageConstructor({
            data: {
                type:'warning',
               ...options
            }
        })
        this.remove()
    
    },
    error(options = {}) {
        instance = new MessageConstructor({
            data: {
                type:'error',
               ...options
            }
        })
        this.remove()
    },
    remove(){

        // instance.top=70*count
        //改为高度累加
        if(document.querySelector('.info:last-of-type')?.scrollHeight){
            sumHeight+=document.querySelector('.info:last-of-type')?.scrollHeight+20
        }
        instance.top=sumHeight
        document.body.appendChild(instance.$mount().$el) //this.$el拿到组件实际上的dom，把他挂载到body上
        count++
        setTimeout(() => {
            document.querySelector('.info').classList.add('fade-out');
        }, 2600); 
        setTimeout(() => {
            // if(document.querySelector('.info:first-of-type')?.scrollHeight){
            //     sumHeight-=document.querySelector('.info:first-of-type')?.scrollHeight+20
            // }
            if(sumHeight>0){
                sumHeight-=document.querySelector('.info')?.scrollHeight+20
            }
            let j=document.querySelector('.info')?.scrollHeight+20
            document.body.removeChild(document.querySelector('.info')) 
            count--
            
            document.querySelectorAll('.info').forEach(element => {
                let prevTop=element.style.top.replace('px','')-0
                element.style.top=(prevTop-j)+'px'
      
            });
        }, 3000);  //3秒之后自动移除dom组件
    }

}

export default MessageTip