<template>
  <!-- 首页轮播 -->
  <div id="main">
    <div class="banner">
      <el-carousel indicator-position="outside">
        <el-carousel-item v-for="(data,index) in imgarr" :key="index">
          <img class="img" :src="$host + data.image" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- <div class="box">
      <img  class="img" :class="{ showing: showimga }" :src="imgb" />
      <img   class="img" :class="{ showimg: showimgb }" :src="imga" />
      <img   style="opacity: 0; width: 100%; height: auto" :src="imgb" />
      <span id="left" title="前一张" @click.prevent="showimg"
        ><a href="javascript:void(0);"> —— PREV</a></span
      >
      <span id="right" title="后一张" @click.prevent="showimg"
        ><a href="javascript:void(0);">NEXT —— </a></span
      >
    </div>
    <div id="arrow">
      <div class="arrow" @click="showimg" :class="{ arrow1: showimga }"></div>
      <div class="arrow" @click="showimg" :class="{ arrow1: showimgb }"></div>
    </div> -->
  </div>
</template>

<script>
import { getBanner } from "@/api/news/index";
export default {
  data() {
    return {
      imgarr: [],
      imga: require("../../../public/images/home-img2_a.jpg"),
      imgb: require("../../../public/images/home-img2_b.jpg"),
      imga_sp: require("../../../public/images/home-img2_a-sp.jpg"),
      imgb_sp: require("../../../public/images/home-img2_b-sp.jpg"),
      showimga: true,
      showimgb: false,
    };
  },
  methods: {
    async getInfo() {
      const res = await getBanner();
      if (res.code == 0) {
        this.imgarr = res.data;

      }
    },
    showimg() {
      this.showimga = !this.showimga;
      this.showimgb = !this.showimgb;
    },
  },
  mounted() {
    setInterval(() => {
      this.showimg();
    }, 5000);
  },
  created() {
    this.getInfo();
  },
};
</script>

<style scoped lang="less">
.banner {
  ::v-deep .el-carousel__container {
    height: 500px;
  }
}
#main {
  position: relative;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  margin-top: 100px;
  /* display: flex; */
  justify-content: center;
}
/* .phoneBox {
        display: block;
    } */
.box {
  /* display: none; */

  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  max-width: 1400px;
  height: auto;
}
.img {
  width: 100%;
  max-width: 1400px;
  max-width: inherit;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  height: auto;
  opacity: 1;
  transition: opacity 0.5s ease;
  border-radius: 5px;
}
.img.showimg {
  z-index: 100;
  opacity: 0;
}
#left,
#right,
a {
  z-index: 300;
  color: #ffffff;
  font-size: 20px;
}
#left {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 25%;
}
#right {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 25%;
}

#arrow {
  width: 36px;
  height: 12px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  bottom: 5%;
  /* z-index: 300; */
}
.arrow {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 6px;
}
.arrow:hover {
  background-color: #c78a03;
}
.arrow.arrow1 {
  background-color: #c78a03;
}
@media screen and (max-width: 1200px) {
  .banner {
    ::v-deep .el-carousel__container {
      height: 300px;
    }
  }
}
@media screen and (max-width: 768px) {
  .banner {
    ::v-deep .el-carousel__container {
      height: 160px;
    }
  }
  #main {
  margin-top: 50px;
}
}
</style>
