import '@/css/elementStyle/index.css';
import '@/css/goabal.css';
import axios from 'axios';
import ElementUI from 'element-ui';
import Vue from 'vue';
import App from './App.vue';
// import  './common/chinese';
import router from './router';
import store from './store';
// import '@/css/goabal.css';
import Loading from '@/components/Loading/index.vue';
import MessageTip from '@/components/Message/index';
import PopUP from '@/components/PopUp/index.vue';
import { lazyPlugin } from '@/directives/index';
import md5 from 'js-md5';
// axios.defaults.baseURL = 'http://43.136.51.141/web'
Vue.prototype.$http = axios

Vue.prototype.$host = process.env.VUE_APP_BASE_URL // 服务器路径常量

Vue.prototype.$md5 = md5;
// Vue.prototype.$common = common;

Vue.prototype.$MessageTip = MessageTip
Vue.use(ElementUI)
Vue.use(lazyPlugin)
Vue.config.productionTip = false
Vue.component("Loading",Loading)
Vue.component("PopUP",PopUP)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
