<template>
  <div :class="[type, 'info', 'box']" v-if="isShow" :style="'top:' + top + 'px'">
    <i :class="type == 'success'
        ? 'el-icon-success'
        : type == 'error'
          ? 'el-icon-error'
          : type == 'warning'
            ? 'el-icon-warning'
            : 'message'
      "></i>

    {{ info }}
  </div>
  <!-- <div :class="['box-main ', { active: isShow }]">{{ info }}</div> -->
</template>

<script>
import { Lang } from "@/common/chinese";
export default {
  data() {
    return {
      info: "",
      isShow: false,
      type: "success",
      top: 0
    };
  },
  mounted() {
    this.isShow = true;
 
    setTimeout(() => {
      Lang.zh_tran(Lang.zh_getLang());
    }, 10);
  },
  methods: {},
};
</script>

<style scoped>
.box {
  left: 50%;
  top: 0;
  position: fixed;
  z-index: 10001;
}

.success {
  border: 1px solid #8cd666;
  background-color: #f0f9eb;
  color: #67c23a;
}

.message {
  border: 1px solid #909399;
  background-color: #f4f4f5;
  color: #909399;
}

.warning {
  border: 1px solid #e6a23c;
  background-color: #fdf6ec;
  color: #e6a23c;
}

.error {
  border: 1px solid #f56c6c;
  background-color: #fef0f0;
  color: #f56c6c;
}

@keyframes example {
  from {
    margin-top: -70px;
    opacity: .0;
  }

  to {
    margin-top: 20px;
    opacity: 1;
  }

}

@keyframes out {
  from {
    margin-top: 20px;
    opacity: 1;
  }

  to {
    margin-top: -70px;
    opacity: .0;
  }

}

.info {
  transition: all 0.2s linear;
  animation-name: example;
  animation-duration: .4s;
  min-width: 300px;
  max-width: 300px;
  /* overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis; */
  line-height: 30px;
  margin-left: -150px;
  /* height: 50px; */
  display: flex;
  align-items: center;

  font-weight: bold;
  margin-top: 20px;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 10px;
}

.fade-out {
  animation-name: out;
  animation-duration: .4s;
}

.info i {
  font-size: 20px;

  margin-right: 10px;
}

.el-icon-success {
  color: #67c23a;
}

.el-icon-error {
  color: #f56c6c;
}

.el-icon-info {
  color: #909399;
}

.el-icon-warning {
  color: #e6a23c;
}

.box-main {
  width: 150px;
  min-height: 50px;
  background: #ccc;
  text-align: center;
  line-height: 50px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.active {
  transition: all 1s;
  opacity: 0;
}
</style>
